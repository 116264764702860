<template>
  <v-card>
    <v-card-title>
      <span> Exportação Folha </span>

      <v-spacer />

      <v-btn
        v-show="hiddenExportButton"
        class="mr-2"
        color="warning"
        data-test="button-export"
        outlined
        :disabled="disableExportButton"
        @click="exportCsv()"
      >
        <v-icon
          size="30"
          class="me-2"
        >
          {{ icons.mdiDownload }}
        </v-icon>
        <span> Exportar CSV </span>
      </v-btn>

      <v-btn
        v-show="hiddenExportButton"
        class="ml-2"
        color="error"
        data-test="button-export"
        outlined
        :disabled="disableExportButton"
        @click="getPdf()"
      >
        <div v-if="!isLoadingExportPdf">
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiFilePdfBox }}
          </v-icon>
          <span> Exportar PDF </span>
        </div>

        <v-progress-circular
          v-else
          color="white"
          indeterminate
        >
        </v-progress-circular>
      </v-btn>

      <v-btn
        color="info"
        class="ml-4"
        outlined
        @click="openFilterModal()"
      >
        <v-icon
          size="30"
          class="me-2"
        >
          {{ icons.mdiFilterPlusOutline }}
        </v-icon>
        Filtros
      </v-btn>

      <v-btn
        class="ml-4"
        outlined
        @click="clearFilter()"
      >
        <v-icon
          size="30"
          class="me-2"
        >
          {{ icons.mdiFilterRemoveOutline }}
        </v-icon>
        Limpar filtros
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="itemsTable"
      :loading="loading"
      :sort-by="'user.name'"
      :sort-desc="false"
      :group-by="['fantasy_name']"
      loading-text="Carregando dados..."
      no-data
    >
      <template v-slot:no-data>
        <span>Sem informações para exibir</span>
      </template>

      <template v-slot:group.header="{ items }">
        <th :colspan="headers.length">
          <h3>{{ items[0].fantasy_name }}</h3>
        </th>
      </template>

      <template v-slot:group.summary>
        <td>Total</td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <h4>
            {{ totalValues }}
          </h4>
        </td>
        <td></td>
      </template>

      <template v-slot:item.event.name="{ item }">
        {{ item.event.code }} - {{ item.event.name }}
      </template>

      <template #[`item.value`]="{ item }">
        {{ Number(item.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ dateFormat(item.created_at) }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="showFilterModal"
      width="700px"
      persistent
    >
      <FilterModal
        :event-list="eventList"
        :employees-list="employeesList"
        :list-of-companies="listOfCompanies"
        @setDataFromFilterInTable="(data, eventData) => setDataFromFilterInTable(data, eventData)"
        @close="showFilterModal = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiDownload, mdiEye,
  mdiFilePdfBox,
  mdiFilterPlusOutline, mdiFilterRemoveOutline,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import FilterModal from './FilterModal.vue'

const JSZip = require('jszip')

export default {
  components: {
    FilterModal,
  },

  mixins: [formatters, messages],

  data() {
    return {
      headers: [
        {
          text: 'PROTOCOLO', value: 'protocol', align: 'left', sortable: false,
        },
        {
          text: 'DIGITADOR', value: 'user_created.name', align: 'left', sortable: false,
        },
        {
          text: 'EVENTO', value: 'event.name', align: 'left', sortable: false,
        },
        {
          text: 'COLABORADOR', value: 'user.name', align: 'left', sortable: false,
        },
        {
          text: 'VALOR', value: 'value', align: 'left', sortable: false,
        },
        {
          text: 'REFERÊNCIA', value: 'reference', align: 'center', sortable: false,
        },
        {
          text: 'FILIAL', value: 'fantasy_name', align: 'center', sortable: false,
        },
      ],

      companyName: '',
      totalValues: 0,

      eventList: [],
      itemsTable: [],
      userProfile: {},
      employeesList: [],
      commissionList: [],
      listOfCompanies: [],
      eventDataFromFile: [],

      loading: false,
      loadingExport: false,
      showFilterModal: false,
      disableExportButton: true,
      isLoadingExportPdf: false,

      icons: {
        mdiEye,
        mdiDownload,
        mdiFilePdfBox,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
      },
    }
  },

  computed: {
    hiddenExportButton() {
      const profile = this.userProfile?.profile?.profile
      const IsACallCenterAttendance = profile !== 'Departamento Pessoal'

      return !IsACallCenterAttendance
    },
  },

  created() {
    this.employeesList = localStorageSlim.get('listEmployees', { decrypt: true })
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })

    this.getEventList()
    this.getListOfCompanies()
  },

  /**
   * @typedef {Object} Events
   * @property {string} id
   * @property {number} code
   * @property {string} name
   * @property {number} totalValue
   * @property {string} fantasyName
   */

  /**
   * @typedef {Object} Data
   * @property {number} codSelf
   * @property {Object.<string, Events>} events
   * @property {string} fantasyName
   */

  /**
   * An array with the total value for each event of each user.
   *
   * @typedef {Object} UserValuesByEvent
   * @property {string} name - The user name.
   * @property {Data} data - The user's event data including event codes, names, IDs and total value.
   */
  methods: {
    sum(items, field) {
      const result = items.reduce((sum, item) => sum + Number(item[field]), 0)

      return this.formatBrazilianCurrency(result)
    },

    async getEventList() {
      await axiosIns.get('api/v1/integration/event/index').then(response => {
        response.data.data.map(({ id, name, code }) => {
          this.eventList.push({
            id,
            name: `${code} - ${name}`,
            nameWithoutCode: name,
            code,
          })
        })

        this.eventList.sort(({ code: firstCode }, { code: secondCode }) => firstCode - secondCode)
      }).catch(error => this.showErrorMessage(error))
    },

    async getListOfCompanies() {
      await axiosIns.get('api/v1/records/company/index').then(response => {
        this.listOfCompanies = response.data.data
      }).catch(error => this.showErrorMessage(error))
    },

    async exportCsv() {
      /**
       * An array with only the fantasy_name property of the companies.
       * @type {string[]}
       */
      const listOfCompanyNames = this.listOfCompanies.map(({ fantasy_name: fantasyName }) => fantasyName)

      /**
       * The event code extracted from the eventDataFromFile object using optional chaining.
       * @type {string | undefined}
       */
      const eventCode = this.eventDataFromFile?.code

      /**
       * The JSZip instance that will hold the zip file.
       * @type {JSZip}
       */
      const zip = new JSZip()

      listOfCompanyNames.forEach(companyName => {
        const dataFilteredByCompany = this.filterByCompany(companyName)
        const totalValuesPerEvent = this.getTotalValuesPerEvent(dataFilteredByCompany)

        // Get the CSV file content for the current totalValuesPerEvent result and store it in a variable
        const file = this.getCsv(totalValuesPerEvent)

        // Add a new file into the zip folder with the current companyName and eventCode, if available
        zip.folder('Planilhas').file(`${this.eventDataFromFile ? `${eventCode}-` : ''}${companyName}.csv`, file)
      })

      zip.generateAsync({ type: 'blob' }).then(blob => {
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = 'planilhas.zip'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },

    async getPdf() {
      this.isLoadingExportPdf = true

      const body = [...this.itemsTable]

      await axiosIns.post('api/v1/integration/typing_sheet/export_sheet_pdf', body, { responseType: 'blob' }).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const pdfUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.href = pdfUrl
        link.download = 'Comissões.pdf'
        window.open(pdfUrl, '_blank')
      })
        .catch(error => this.showErrorMessage(error))
        .finally(() => { this.isLoadingExportPdf = false })
    },

    /**
     * Calculates the total value for each event of each user.
     *
     * @param {Array} users - An array of objects containing user information, event details, and values.
     * @returns {Array.<UserValuesByEvent>} An object with the total value for each event of each user.
     */
    getTotalValuesPerEvent(users) {
      const usersValuesByEvent = {}

      users.forEach(user => {
        const {
          // eslint-disable-next-line camelcase
          user: { name, cod_self },
          // eslint-disable-next-line camelcase
          fantasy_name,
          event,
          value,
        } = user
        const eventId = event.id
        const eventName = event.name
        const eventCode = event.code

        if (!usersValuesByEvent[name]) {
          usersValuesByEvent[name] = {
            codSelf: cod_self,
            events: {
              fantasyName: fantasy_name,
              [eventCode]: {
                id: eventId,
                name: eventName,
                code: eventCode,
                totalValue: Number(value),
              },
            },
          }
        } else {
          if (!usersValuesByEvent[name].events[eventCode]) {
            usersValuesByEvent[name].events[eventCode] = {
              id: eventId,
              name: eventName,
              code: eventCode,
              totalValue: 0,
            }
          }

          usersValuesByEvent[name].events[eventCode].totalValue += Number(value)
          // eslint-disable-next-line camelcase
          usersValuesByEvent[name].fantasyName = fantasy_name
          // eslint-disable-next-line camelcase
          usersValuesByEvent[name].codSelf = cod_self
        }
      })

      /**
       * An array of objects with the total value for each event of each user.
       *
       * @type {Array.<UserValuesByEvent>}
       */
      const listWithUsersValuesByEvent = Object.entries(usersValuesByEvent).map(([name, data]) => ({ name, data }))

      return listWithUsersValuesByEvent
    },

    /** Returns a Blob object containing a CSV file generated from an array of objects
     * that contain string and number properties provided as a parameter.
     *
     * @async
     * @function
     * @param {Array.<UserValuesByEvent>} listWithUsersValuesByEvent - Array containing objects with string and number properties.
     * @returns {Promise<Blob>} Returns a Blob object containing the CSV data.
     */
    async getCsv(listWithUsersValuesByEvent) {
      this.loaderExport = true
      let csv = ''

      if (listWithUsersValuesByEvent.length > 0) {
        listWithUsersValuesByEvent.map(({ data: { events, codSelf } }) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const event of Object.values(events)) {
            // eslint-disable-next-line no-continue
            if (!codSelf || !event?.code) continue

            const totalValue = this.removeSpecialCharactersFromCommission(event.totalValue)

            csv += `${Number(codSelf ?? 0)};${event.code ?? 0};${totalValue}\n`
          }
        })
      }

      const file = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

      return file
    },

    /**
     * Filters an array of items by their company name.
     * @param {string} companyName - The name of the company to filter by.
     * @returns {array} - A new array containing the items with the specified company name.
     */
    filterByCompany(companyName) {
      const { itemsTable } = this
      const result = itemsTable.filter(({ fantasy_name: fantasyName }) => fantasyName === companyName)

      return result
    },

    /**
     * @param {string} value
     * @returns {number}
     */
    removeSpecialCharactersFromCommission(value) {
      const removeCharactersRegex = /[^\d]/g
      const BrazilianStandardValueFormat = Number(value).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })

      return Number(BrazilianStandardValueFormat.replace(removeCharactersRegex, ''))
    },

    openFilterModal() {
      this.showFilterModal = true
    },

    /**
     * @typedef {Object} EventData
     * @property {string} id - the identifier of the event
     * @property {string} name - the name of the event
     * @property {string} nameWithoutCode - the name of the event without the code
     * @property {number} code - the code of the event
     */
    /**
     * This function sets the data for an items table and event data from a filter, and enables the export button if the table has any data.
     * @param {Array} data - the filtered data for the items table
     * @param {EventData} eventData - the event data associated with the filtered items
     */
    setDataFromFilterInTable(data, eventData) {
      this.itemsTable = data
      this.eventDataFromFile = eventData
      this.totalValues = this.sumValues(data, 'value')

      if (this.itemsTable) {
        this.disableExportButton = false
      }
    },

    sumValues(items, field) {
      const hasField = items[0][field]

      if (hasField) {
        const result = items.reduce((sum, item) => sum + Number(item[field]), 0)

        return this.formatBrazilianCurrency(result)
      }

      return '0'
    },

    clearFilter() {
      this.itemsTable = []
      this.disableExportButton = true
    },
  },
}
</script>
