<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="mb-4">Filtrar dados</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-dialog
          ref="dialogReferenceDate"
          v-model="showCalendarReferenceDate"
          :return-value.sync="referenceDate"
          width="400px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedReferenceDate"
              v-bind="attrs"
              :append-icon="icons.mdiCalendar"
              label="Mês Referência"
              readonly
              outlined
              dense
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="referenceDate"
            width="400px"
            locale="pt-BR"
            type="month"
            scrollable
            @dblclick:month="handleMonthSelection()"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="showCalendarReferenceDate = false"
            >
              Cancelar
            </v-btn>

            <v-btn
              text
              color="primary"
              @click="$refs.dialogReferenceDate.save(referenceDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-text-field
          v-model="protocol"
          label="Número de protocolo"
          outlined
          dense
        />

        <v-autocomplete
          v-model="typistUser"
          :items="employeesList"
          item-text="name"
          item-value="id"
          label="Digitador"
          clearable
          outlined
          dense
        />

        <v-autocomplete
          v-model="employeeIdSelected"
          :items="employeesList"
          item-text="name"
          item-value="id"
          label="Colaborador"
          clearable
          outlined
          dense
        />

        <v-autocomplete
          v-model="eventIdSelected"
          :items="eventList"
          item-text="name"
          item-value="id"
          label="Evento"
          clearable
          outlined
          dense
        />

        <v-autocomplete
          v-model="selectedCompanyId"
          :items="listOfCompanies"
          item-text="fantasy_name"
          item-value="id"
          label="Filial"
          clearable
          outlined
          dense
        />
      </v-form>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          @click="closeModal(), resetForm()"
        >
          Fechar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="getFilterData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiCalendar } from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  props: {
    employeesList: {
      type: Array,
      required: true,
    },

    eventList: {
      type: Array,
      required: true,
    },

    listOfCompanies: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      protocol: '',
      typistUser: '',
      referenceDate: '',
      eventIdSelected: '',
      selectedCompanyId: '',
      employeeIdSelected: '',

      isLoading: false,
      showCalendarReferenceDate: false,

      icons: {
        mdiCalendar,
      },
    }
  },

  computed: {
    computedReferenceDate: {
      get() {
        return this.referenceDate ? this.formatDate(this.referenceDate) : ''
      },

      set() {},
    },
  },

  methods: {
    async getFilterData() {
      const {
        referenceDate, typistUser, employeeIdSelected, eventIdSelected, selectedCompanyId, protocol,
      } = this

      const body = {
        protocol,
        reference: referenceDate,
        users_created_id: typistUser,
        users_id: employeeIdSelected,
        events_id: eventIdSelected,
        companies_id: selectedCompanyId,
      }

      this.isLoading = true

      await axiosIns
        .post('api/v1/integration/typing_sheet/filter_leaf', body)
        .then(response => {
          const dataFiltered = response.data

          if (dataFiltered.length === 0) {
            this.showMessage({ title: 'Nenhum registro encontrado!', text: 'Tente novamente' })
            this.isLoading = false

            return
          }

          this.showMessage({ title: 'Sucesso', text: 'Dados obtidos com sucesso', icon: 'success' })

          this.sendFilterData(dataFiltered)
        })
        .catch(error => {
          this.showMessage({ title: 'Erro ao enviar dados', text: error, icon: 'error' })
        }).finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },

    sendFilterData(data) {
      if (typeof data !== 'object') return

      const selectedEvent = this.eventList.find(({ id }) => id === this.eventIdSelected)

      this.$emit('setDataFromFilterInTable', data, selectedEvent)
    },

    handleMonthSelection() {
      this.$refs.dialogReferenceDate.save(this.referenceDate)
      this.showCalendarReferenceDate = false
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns {string}
     */
    formatDate(date) {
      if (date) {
        const [year, month] = date.split('-')

        return `${month}-${year}`
      }

      return date
    },

    async resetForm() {
      await this.$nextTick()
      this.$refs.form.reset()
      this.eventIdSelected = ''
      this.referenceDate = ''
    },
  },
}
</script>
